import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { FiYoutube } from "@react-icons/all-files/fi/FiYoutube";
import { FiTwitter } from "@react-icons/all-files/fi/FiTwitter";
import { FiInstagram } from "@react-icons/all-files/fi/FiInstagram";
import { RiFacebookBoxLine } from "@react-icons/all-files/ri/RiFacebookBoxLine";
import { RiLinkedinBoxLine } from "@react-icons/all-files/ri/RiLinkedinBoxLine";

import Link from '../../atoms/Link/Link';


function SocialMediaList({ primary }) {

    const data = useStaticQuery(
        graphql
        `
        query {
            globals: allSanitySingleGlobals {
              nodes {
                socialsOrder {
                  name
                  slug {
                    current
                  }
                  href
                }
              }
            }
          }
        `,
    );
    
    const props = {

      className: `inline-block ${primary ? "text-brand-red h-8 w-8" : "text-brand-orange h-5 w-5"}`,
    }

    const socialMediaItems = {
      "youtube": <FiYoutube {...props}/>,
      "twitter": <FiTwitter {...props}/>,
      "instagram": <FiInstagram {...props}/>,
      "facebook": <RiFacebookBoxLine {...props}/>,
      "linkedin": <RiLinkedinBoxLine {...props}/>,
    };

    return(
        <div className={`flex items-center ${primary ? "space-x-8" : "space-x-5"}`}>
            {data.globals.nodes[0].socialsOrder.map((social) => {
                return <Link target="blank" to={social.href}>{socialMediaItems[`${social.slug.current}`]}</Link> 
            })}
        </div>
    );
}

export default SocialMediaList;