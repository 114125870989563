import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from './atoms/Link/Link';
import SocialMediaList from './organisms/SocialMediaList/SocialMediaList';
import goTopButton from '../images/icons/go-top-button.svg';

function Footer() {

  const scrollToTop = () => {
    if (typeof window !== `undefined`) {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  const data = useStaticQuery(
    graphql
    `
    query {
        globals: allSanitySingleGlobals {
          nodes {
            address
            contactEmail
            faxNumber
            telephoneNumber
          }
        }
        navs: allSanityNavigation {
          nodes { 
            navigationBuilder {
              ... on SanityButtonLinkEntry {
                _key
                _type
                buttonLabel
                entry {
                  slug {
                    current
                  }
                }
              }
              ... on SanityButtonLinkExternal {
                _key
                _type
                buttonLabel
                href
              }
              ... on SanityButtonLinkInternal {
                _key
                _type
                buttonLabel
                uri
              }
              ... on SanityButtonMedia {
                _key
                _type
                buttonLabel
                file {
                  _key
                  _type
                  _rawAsset
                }
              }
            }
          }
        }
      }
    `,
  );

  const { address, telephoneNumber, faxNumber, contactEmail} = data.globals.nodes[0];

  const navLoop = data.navs.nodes[0].navigationBuilder.map((item, index) => (
    <>
      <Link to={`${item.entry ? (item.entry.slug.current === "index" ? "/" : `/${item.entry.slug.current}`) : item.uri}`} className="text-white font-lato text-lg leading-5 inline-flex items-center">
        {item.buttonLabel}
      </Link>
    </>
  ));

  return (
    <>
      <footer className="bg-black text-white pt-16 pb-8 flex">
        <div className="w-1/6" />
        <div className="w-4/6">
          <div className="grid gap-10 grid-cols-1 lg:grid-cols-3 mb-8">
            <div className="mb-8">
              <div className="mb-8 font-lato font-bold uppercase">Head Office</div>
              <pre><p className="font-lato">{address}</p></pre>
            </div>
            <div className="mb-8">
              <div className="mb-8 font-lato font-bold uppercase">Contact Us</div>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex gap-x-2">
                  <span>{telephoneNumber}</span>
                </div>
                <div className="flex gap-x-2">
                  <span>{faxNumber}</span>
                </div>
                <div className="flex gap-x-2">
                  <span>{contactEmail}</span>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <div className="mb-8 font-lato font-bold uppercase">Quick Links</div>
              <div className="flex flex-col gap-y-10">
                {navLoop}
              </div>
            </div>
          </div>
          <hr className="mb-6 border-brand-orange" />
          <div className="text-center mb-5 font-lato font-light">{`© ${new Date().getFullYear()} Red Door - Aigboje Aig-Imoukhuede`}</div>
          <div className="flex justify-center">
            <SocialMediaList/>
          </div>
        </div>
        <div className="w-1/6 flex justify-center items-end">
          <button onClick={scrollToTop} className="w-8 h-8"><img src={goTopButton} alt=""/></button>
        </div>
      </footer>
    </>
  );
}

export default Footer;
