import React from 'react';
import PropTypes from 'prop-types';

function Container({ className, children }) {
  return (
    <div className={`pt-10 pb-16 ${className}`} >
        <div className="w-11/12 xl:w-4/6 mx-auto">{children}</div>
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  className: "",
};

export default Container;