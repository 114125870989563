import PropTypes from 'prop-types';
import React from 'react';
import Heading from '../../atoms/Heading/Heading';

const FancyHeading = ({ headingLevel, center, darkBg, children }) => (
  <>
    <div className="mb-9">
      <Heading
        headingLevel={headingLevel}
        className={`font-lato font-light uppercase text-4xl mb-5 ${center ? "text-center" : ""} ${darkBg ? "text-white" : "text-brand-dark-grey"}`}
      >
        {children}
      </Heading>
      <div className={`w-24 h-1 ${center ? "mx-auto" : ""} ${darkBg ? "bg-white" : "bg-brand-red"}`}  ></div>
    </div>
  </>
);

FancyHeading.propTypes = {
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
  headingLevel: PropTypes.number,
};

FancyHeading.defaultProps = {
  headingLevel: 1,
  children: 'Heading Not Set',
};

export default FancyHeading;