import React from 'react';
import PropTypes from 'prop-types';

import Footer from './footer';
import Nav from './nav';

import './layout.css';

import '@fontsource/lato/300.css';
import '@fontsource/lato/300-italic.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/400-italic.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/700-italic.css';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Layout = ({ children }) => (
  <>
    <Nav />
    <div>
      <main>{children}</main>
      <Footer />
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;