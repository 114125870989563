import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from './atoms/Link/Link';

function Nav() {
  const data = useStaticQuery(
    graphql
    `
    query {
        navs: allSanityNavigation {
          nodes { 
            navigationBuilder {
              ... on SanityButtonLinkEntry {
                _key
                _type
                buttonLabel
                entry {
                  slug {
                    current
                  }
                }
              }
              ... on SanityButtonLinkExternal {
                _key
                _type
                buttonLabel
                href
              }
              ... on SanityButtonLinkInternal {
                _key
                _type
                buttonLabel
                uri
              }
              ... on SanityButtonMedia {
                _key
                _type
                buttonLabel
                file {
                  _key
                  _type
                  _rawAsset
                }
              }
            }
          }
        }
      }
    `,
  );


  const navLoop = data.navs.nodes[0].navigationBuilder.map((item, index) => (
    <>
      <Link
        to={`${item.entry ? (item.entry.slug.current === "index" ? "/" : `/${item.entry.slug.current}`) : item.uri}`}
        className="text-brand-dark-grey font-lato text-sm md:text-base lg:text-lg leading-5 inline-flex items-center hover:text-brand-red"
      >
        {item.buttonLabel}
      </Link>
      {index < data.navs.nodes[0].navigationBuilder.length - 1 ? <div className="bg-brand-lighter-grey w-px h-4"/> : <></>}
    </>
  ));

  return (
    <nav className="relative shadow-lg py-4 z-10">
      <div className="w-11/12 md:w-4/6 mx-auto">
        <div className="flex flex-nowrap justify-around py-1 items-center">
            {navLoop}
        </div>
      </div>
    </nav>
  );
}

export default Nav;
