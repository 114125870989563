import PropTypes from 'prop-types';
import React from 'react';

function Heading({ headingLevel, className, children }) {
  const Tag = `h${headingLevel}`; // make sure this has a default value of "1" or w/e

  return (
    <Tag className={className}>{children}</Tag>
  );
}

Heading.propTypes = {
  headingLevel: PropTypes.number,
};

Heading.defaultProps = {
  headingLevel: 2,
};

export default Heading;